<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
        destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="shangPinMC" label="商品名称" />
            <x-detail-item prop="shangPinHuoHao" label="商品货号" />
            <x-detail-item prop="shangPinTiaoMa" label="商品条码" />
            <x-detail-item prop="shangPinKuCun" label="商品库存" />
            <x-detail-item prop="shangPinLeiBie" label="商品类别" />
            <x-detail-item prop="shangPinJinJia" label="商品进价" />
            <x-detail-item prop="dianNeiLSJ" label="店内零售价" />
            <x-detail-item prop="shangPinPinPai" label="商品品牌" />
            <x-detail-item prop="shangPinPaiXu" label="商品排序" />
            <x-detail-item prop="shangPinChanDi" label="商品产地" />
            <x-detail-item prop="shangPinDW" label="商品单位" />
            <x-detail-item prop="shengChanRQ" label="生产日期" />
            <x-detail-item prop="guoQiRQ" label="过期日期" />
            <x-detail-item prop="shangPinChaoLiang" label="商品超量" />
            <x-detail-item prop="baoZhiQi" label="保质期" />
            <x-detail-item prop="kuCunYuJing" label="库存预警" />
            <x-detail-item prop="shiFuChengZhong" label="是否称重" />
            <x-detail-item prop="shangPinZT" label="商品状态" />
            <x-detail-item prop="shiFuDianNeiChuShou" label="是否店内出售" />
            <x-detail-item prop="shiFuDianNeiChangYong" label="是否店内常用" />
            <x-detail-item prop="shiFuYunXuChaiFen" label="是否允许拆分" />
            <x-detail-item prop="shiFuYunXuTuiKuan" label="是否允许退款" />
            <x-detail-item prop="shiFuYunXuHuanHuo" label="是否允许换货" />
            <x-detail-item prop="shangPinFengMianTu" label="商品封面图" />
            <x-detail-item prop="shangPinXiangQing" label="商品详情" />
            <x-detail-item prop="guiShuMenDian" label="归属门店" />
            <x-detail-item prop="guiShuCangKu" label="归属仓库" />
            <x-detail-item prop="shiFuZengPin" label="是否赠品" />
            <x-detail-item prop="zengPinKuSL" label="赠品库数量" />
            <x-detail-item prop="shiFuDuoGuiGe" label="是否多规格" />
            <x-detail-item prop="ziDongBH" label="自动编号" />
            <x-detail-item prop="ziDongShengChengTiaoMa" label="自动生成条码" />
            <x-detail-item prop="ziDongShengChengPLUMa" label="自动生成PLU码" />
            <x-detail-item prop="dingJiGuiGe" label="顶级规格" />
            <x-detail-item prop="ciJiGuiGe" label="次级规格" />
            <x-detail-item prop="ciJiGuiGeShangPinTuPian" label="次级规格商品图片" />
            <x-detail-item prop="guiGeHuoHao" label="规格货号" />
            <x-detail-item prop="guiGeTiaoMa" label="规格条码" />
            <x-detail-item prop="guiGePLUMa" label="规格PLU码" />
            <x-detail-item prop="guiGeShangPinJinJia" label="规格商品进价" />
            <x-detail-item prop="guiGeShangPinLSJ" label="规格商品零售价" />
            <x-detail-item prop="guiGeShangPinKuCun" label="规格商品库存" />
            <x-detail-item prop="guiGeShangPinChaoLiang" label="规格商品超量" />
            <x-detail-item prop="guiGeShangPinYuJing" label="规格商品预警" />
            <x-detail-item prop="shiFuShanChu" label="是否删除" />
        </x-detail>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/spgl/ShangPinXX";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                shangPinMC: "", // 商品名称
                shangPinHuoHao: "", // 商品货号
                shangPinTiaoMa: "", // 商品条码
                shangPinKuCun: "", // 商品库存
                shangPinLeiBie: "", // 商品类别
                shangPinJinJia: "", // 商品进价
                dianNeiLSJ: "", // 店内零售价
                shangPinPinPai: "", // 商品品牌
                shangPinPaiXu: "", // 商品排序
                shangPinChanDi: "", // 商品产地
                shangPinDW: "", // 商品单位
                shengChanRQ: "", // 生产日期
                guoQiRQ: "", // 过期日期
                shangPinChaoLiang: "", // 商品超量
                baoZhiQi: "", // 保质期
                kuCunYuJing: "", // 库存预警
                shiFuChengZhong: "", // 是否称重
                shangPinZT: "", // 商品状态
                shiFuDianNeiChuShou: "", // 是否店内出售
                shiFuDianNeiChangYong: "", // 是否店内常用
                shiFuYunXuChaiFen: "", // 是否允许拆分
                shiFuYunXuTuiKuan: "", // 是否允许退款
                shiFuYunXuHuanHuo: "", // 是否允许换货
                shangPinFengMianTu: "", // 商品封面图
                shangPinXiangQing: "", // 商品详情
                guiShuMenDian: "", // 归属门店
                guiShuCangKu: "", // 归属仓库
                shiFuZengPin: "", // 是否赠品
                zengPinKuSL: "", // 赠品库数量
                shiFuDuoGuiGe: "", // 是否多规格
                ziDongBH: "", // 自动编号
                ziDongShengChengTiaoMa: "", // 自动生成条码
                ziDongShengChengPLUMa: "", // 自动生成PLU码
                dingJiGuiGe: "", // 顶级规格
                ciJiGuiGe: "", // 次级规格
                ciJiGuiGeShangPinTuPian: "", // 次级规格商品图片
                guiGeHuoHao: "", // 规格货号
                guiGeTiaoMa: "", // 规格条码
                guiGePLUMa: "", // 规格PLU码
                guiGeShangPinJinJia: "", // 规格商品进价
                guiGeShangPinLSJ: "", // 规格商品零售价
                guiGeShangPinKuCun: "", // 规格商品库存
                guiGeShangPinChaoLiang: "", // 规格商品超量
                guiGeShangPinYuJing: "", // 规格商品预警
                shiFuShanChu: "", // 是否删除
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>