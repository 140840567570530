<template>
    <div class="x-page-container" ref="resize">
<!--        <div class="x-page-btn">
            <el-button type="primary" round @click="handleAdd">预警设置</el-button>
        </div>-->
        <el-card class="box-card">
            <div class="text item">
                <div class="info-line">
                    <span>库存数量 ：{{ total }}</span>
                    <!--<el-button size="mini" round type="primary">查看</el-button>-->
                </div>
                <div class="info-line">
                    <span>库存不足商品数 ：{{ stockoutAmounts }}个</span>
                  <!--  <el-button size="mini" round type="primary">查看</el-button>-->
                </div>
                <div class="info-line">
                    <span>库存超量商品数 ：{{ overstockAmounts }}个</span>
                   <!-- <el-button size="mini" round type="primary">查看</el-button>-->
                </div>
                <div class="info-line">
                    <span>将（已）过期商品数 ：{{ expiredAmounts }}个</span>
                    <el-button size="mini" round type="primary" @click="handleAdd">查看</el-button>
                </div>

            </div>
        </el-card>

        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <shang-pin-c-l-list ref="refAdd" @submitted="refresh"/>
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/spgl/ShangPinXX";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/spgl/spxx/ShangPinYuJingEdit";
    import Detail from "@/view/spgl/spxx/ShangPinXXDetail";
    import ShangPinCLList from "@/view/spgl/spxx/ShangPinCLList";
    import {getShangPinXXList} from "@/service/spgl/ShangPinXX";

    export default {
        name: "ShangPinGaiKuangList",
        mixins: [XTableBase],
        components: {Edit, Detail,ShangPinCLList},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                spOptions: [],
                stockoutAmounts: '',
                overstockAmounts: '',
                expiredAmounts: '',
                total: '',
            }
        },
        mounted() {
            this.getSPXXList()
            this.getCardMsg()
        },
        methods: {
            refresh() {
                this.getCardMsg();
                //默认刷新列表方法
                this.table.loading = true;
                return this.refreshService(Object.assign(this.search, this.searchNoClear)).then((response) => {
                    let ret = response.data;
                    this.table.data = ret.records;
                    this.table.total = ret.total || 0;
                    //处理11条数据删除一条不显示问题
                    if (this.searchNoClear.current !== 1 && (!ret.records || ret.records === 0)) {
                        this.searchNoClear.current--
                        this.refresh()
                        return
                    }
                    return response;
                }).catch(error => {
                    throw error;
                }).finally(() => this.table.loading = false)
            },
            getSPXXList() {
                getShangPinXXList().then((response) => {
                    this.spOptions = response.data
                })
            },
            //获取设置卡片显示细信息
            getCardMsg() {
                service.getShangPinGaiKuang().then(res => {
                    this.stockoutAmounts = res.data.stockoutAmounts
                    this.overstockAmounts = res.data.overstockAmounts
                    this.expiredAmounts = res.data.expiredAmounts
                    this.total = res.data.total
                })
            },
            handleAdd(){
                this.$refs.refAdd.showVisible = true;
            },
        }
    }
</script>

<style scoped>
    .text {
        font-size: 14px;
    }

    .item {
        padding: 18px 0;
    }

    .box-card {
        width: 480px;
    }

    .info-line {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .box-card {
        border: 1px solid #ccc; /* 添加卡片边框样式 */
        border-radius: 4px; /* 添加卡片边框圆角 */
    }
</style>
