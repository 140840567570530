<template>
    <el-dialog title="临期商品" :visible.sync="showVisible" @close="cancel" @open="open"
               destroy-on-close center append-to-body width="1900px">

        <div style="display: flex;justify-content:space-between;">
            <div class="x-page-container" ref="resize" style="width: 100%;height: 700px">
                <!-- 查询区 -->
                <div class="x-page-search">
                    <x-search-item label="商品名称">
                        <el-input v-model="searchOne.shangPinMC" size="small">
                        </el-input>
                    </x-search-item>
                    <div class="x-search-item" style="margin-left: 5px">
                        <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                    </div>
                </div>

                <!-- 列表区域 -->
                <el-table ref="table" v-loading="table.loading" :data="table.data" height="500px" border>
                    <el-table-column width="50" label="序号" type="index" />
                    <el-table-column width="180" prop="shangPinMC" label="商品名称" />
                    <el-table-column width="180" prop="shangPinGuiGe" label="商品规格" />
                    <el-table-column width="180" prop="shangPinHuoHao" label="商品货号" />
                    <el-table-column width="180" prop="shangPinTiaoMa" label="商品条码" />
                    <el-table-column width="180" prop="shangPinDW" label="商品单位" />
                    <el-table-column  prop="shengChanRQ" label="生产日期" show-tooltip-when-overflow/>
                    <el-table-column  prop="baoZhiQiTian" label="保质期（天）" />
                    <el-table-column width="180" prop="chengBenJia" label="入库价（元）" />
                    <el-table-column width="180" prop="shiJiKuCun" label="剩余数量" />
                </el-table>
                <!-- 分页区域 -->
                <el-pagination background
                               :total="table.total"
                               @size-change="handleSizeChange"
                               @current-change="handleCurrentChange"
                               layout="total, sizes, prev, pager, next, jumper"/>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    import * as service from "@/service/spgl/ShangPinXX";
    import {ruleBuilder} from '@/util/validate';
    import {DICT_YES_OR_NO} from '@/util/constant'

    export default {
        name: "ShangPinCLList",
        mixins: [],
        data() {
            this.searchNoClear = {current: 1, size: 10};
            this.sels = [];
            this.pageSizes = [10, 20, 30, 40, 50, 100, 500, 1000];
            this.service = service;
            let {buildMax} = ruleBuilder;
            return {
                showVisible:false,
                searchOne:{
                    shangPinMC:""
                },
                table: {
                    loading: false,
                    data: [],
                    total: 0,
                    height: 1000
                },
                selectData:[],
                searchMidData:[]
            }
        },
        activated() {
            this.$route.params.refresh && this.refresh();
            //解决fixed导致表格错位 激活页面重新计算表格布局
            this.$nextTick(() => {
                this.$refs.table.doLayout()
            })
        },
        methods: {
            cancel(){
                this.selectData = []
                this.searchMidData = []
                this.$emit("submitted")
                this.syncVisible = false
            },
            open(){
                this.refresh()
            },
            refresh() { //默认刷新列表方法
                this.table.loading = true;
                return this.service.linQilist(Object.assign(this.searchOne, this.searchNoClear)).then((response) => {
                    let ret = response.data;
                    let ids = []
                    this.searchMidData.forEach(item=>ids.push(item.id))
                    ret.records.forEach((item)=>{
                        if(ids.indexOf(item.id)==-1){
                            item.check = DICT_YES_OR_NO.NO.key
                        }else{
                            item.check = DICT_YES_OR_NO.YES.key
                        }
                    })
                    this.table.data = ret.records;
                    this.table.total = ret.total || 0;
                    //处理11条数据删除一条不显示问题
                    if (this.searchNoClear.current !== 1 && (!ret.records || ret.records === 0)) {
                        this.searchNoClear.current--
                        this.refresh()
                        return
                    }
                    return response;
                }).catch(error => {
                    throw error;
                }).finally(() => this.table.loading = false)
            },
            handleSizeChange(val) { //默认当前页大小变化
                this.searchNoClear.size = val;
                return this.refresh();
            },
            handleCurrentChange(val) { //默认当前页变化
                this.searchNoClear.current = val;
                return this.refresh();
            },
            handleSearch() { //默认查询
                this.searchNoClear.current = 1;
                return this.refresh();
            },
        }
    }
</script>

<style scoped>

</style>
